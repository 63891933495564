<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg-start-img"
        :style="{ backgroundImage: `url('${imgUrl}')`, backgroundRepeat:'no-repeat', backgroundPosition: 'center' }"
      >
        <div class="w-100 h-100 d-lg-flex px-1">
          <!-- Brand logo-->
          <logo />
          <div class="clearfix">
            <b-link
              class="text-danger"
              @click="logoutCompany"
            >
              <span>&nbsp;{{ $t('auth.different_company') }}</span>
            </b-link>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg p-2 p-lg-5"
      >
        <b-overlay
          :show="loading"
          variant="transparent"
          opacity="1"
          blur="5px"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
            >
              {{ $t('Login.Welcome') }}
            </b-card-title>

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2 mb-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Passwordx</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>{{ $t('Login.forgot_password') }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                        autofocus
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Login
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-overlay>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BOverlay, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BCardTitle, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import LogRocket from 'logrocket'

import axios from '@axios'

import store from '@/store'
import { onMounted } from '@vue/composition-api'

import md5 from 'md5'
import useAuthentication from './useAuthentication'

const Sentry = require('@sentry/vue')

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      userEmail: (() => {
        const company = JSON.parse(localStorage.getItem('currentCompany'))
        return company ? company.email : ''
      })(),
      sideImg: require('@/assets/images/pages/login-ipad.png'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.auth.company.start_image) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = store.state.auth.company.start_image
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
  },
  methods: {
    async login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.loading = true
          const currentSlug = localStorage.getItem('currentSlug')
          await axios.get('/sanctum/csrf-cookie')
          await axios.post(`/api/v1/${currentSlug}/login`, {
            email: this.userEmail,
            password: this.password,
            slug: currentSlug,
          })
          .then(async res => {
            const userData = res.data

            userData.ability = await this.mapAbility(userData.permissions)
            userData.extras = {}
            userData.roleName = userData.role
            userData.role = 'registred'
            userData.fullName = userData.name
            userData.username = userData.name
            userData.avatar = (userData.avatar) ? userData.avatar : this.gravatar(userData.email)

            // Guardar userData en localStorage
            localStorage.setItem('userData', JSON.stringify(userData))
            // Asignar permisos ACL/CASL para las routes y can_do()
            this.$ability.update(userData.ability)
            // Set userData en Sentry
            Sentry.configureScope(scope => {
              scope.setUser({
                username: `${currentSlug}-${userData.id}`,
                email: userData.email,
              })
            })

            LogRocket.identify(userData.id, {
              name: `${currentSlug}-${userData.id}`,
              email: userData.email,

              // Add your own custom user variables here, ie:
              // subscriptionType: 'pro'
            })

            // Set userData en Crisp Chat
            window.$crisp.set('user:email', [userData.email])
            window.$crisp.set('user:nickname', [userData.username])
            if (userData.avatar) window.$crisp.set('user:avatar', [userData.avatar])

            // SET userId para Google Analytics
            this.$gtag.config({ user_id: userData.uuid })
            this.$gtag.set({ userId: userData.uuid, event: 'authentication' })

            this.$router.push({ name: 'home' })
            .then(() => {
            })
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.$refs.loginForm.setErrors({
              password: 'Login incorrecto',
            })
          })
        }
      })
    },
    async mapAbility(permissions) {
      const list = [{ action: 'read', subject: 'general' }]
      permissions.map(value => {
        const valor = value.split('.')
        list.push({ action: valor[1], subject: valor[0] })
      })
      return list
    },
    logoutCompany() {
      this.$store.dispatch('auth/logoutCompany')
      // Redirect to select company page
      window.location.replace(process.env.VUE_APP_URL)
    },
    gravatar(email) {
      const hash = md5(email.trim().toLowerCase())
      return `https://www.gravatar.com/avatar/${hash}`
    },
  },
  setup() {
    const {
      currentCompany,
      currentSlug,
    } = useAuthentication()

    onMounted(() => {
      // console.log([currentCompany, currentSlug])
    })

    return {
      currentCompany,
      currentSlug,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.bg-start-img{
  background: no-repeat center center;
  background-size: auto auto;
}
</style>
